import React, { useEffect, useRef } from 'react';
import './index.css';
import { widget } from '../../charting_library';

export const TVChartContainer = () => {
    const chartContainerRef = useRef();

    useEffect(() => {
        const widgetOptions = {
            symbol: 'GENERIC_DATA',
            datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://api.cryptoinitiate.fr/api'),
            interval: 'D',
            container: chartContainerRef.current,
            library_path: '/charting_library/',
            locale: 'fr',
            theme: 'Light',
            timezone: 'Etc/UTC',
            session: '24x7', 
            overrides: {
                "paneProperties.backgroundGradientStartColor": "#020024",
                "paneProperties.backgroundGradientEndColor": "#4f485e",
            },
            disabled_features: [
                'symbol_info',
                'header_widget',
                'symbol_search_hot_key',
                'display_market_status',
                'header_resolutions',
                'header_interval_dialog_button',
                'header_settings',
                'header_fullscreen_button',
                'header_compare',
                'header_undo_redo',
                'header_screenshot',
                'header_chart_type',
                'control_bar',
                'left_toolbar',
                'timeframes_toolbar',
                'legend_context_menu',
                'volume_force_overlay',
            ],
            enabled_features: [],
            charts_storage_url: 'https://saveload.tradingview.com',
            charts_storage_api_version: '1.1',
            client_id: 'tradingview.com',
            user_id: 'public_user_id',
            fullscreen: true,
            autosize: true,
            studies_overrides: {
                "volume.volume.color.0": "rgba(0,0,0,0)",
                "volume.volume.color.1": "rgba(0,0,0,0)",
            },
            overrides: {
                "paneProperties.backgroundGradientStartColor": "#020024",
                "paneProperties.backgroundGradientEndColor": "#4f485e",
                "paneProperties.fixLeftEdge": true, // Forcer l'affichage du début des données
                "paneProperties.fixRightEdge": true, // Forcer l'affichage de la fin des données
                "scalesProperties.showRightScale": true, // S'assurer que l'échelle de droite est visible
            },
        };

        const tvWidget = new widget(widgetOptions);      

        tvWidget.onChartReady(() => {
            tvWidget.chart().setChartType(3); // Graphique en ligne

            const allStudies = tvWidget.chart().getAllStudies();
            allStudies.forEach((study) => {
                if (study.name === 'Volume') {
                    tvWidget.chart().removeEntity(study.id);
                }
            });
        });
        
        return () => {
            tvWidget.remove();
        };
    }, []);

    return (
        <div
            ref={chartContainerRef}
            className={'TVChartContainer'}
        />
    );
};